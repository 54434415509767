export function init() {

	var $mainSlider = document.querySelectorAll('.main-slider'),
		$sliderHolder = document.querySelectorAll('.slider-holder');

	if($mainSlider) {
		$('.main-slider').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			autoplay: true,
			fade: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>'
		});
	}

	if($sliderHolder) {
		$('.slider-holder').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 3,
			slidesToScroll: 1,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: true,
			prevArrow: '<span class="slick-prev icon-left-arrow"></span>',
			nextArrow: '<span class="slick-next icon-right-arrow"></span>',
			appendArrows: document.querySelector('.arrows-hld'),
			responsive: [
		    {
		      breakpoint: 800,
		      settings: {
		        slidesToShow: 2
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1
		      }
		    }
		    // You can unslick at a given breakpoint now by adding:
		    // settings: "unslick"
		    // instead of a settings object
		  	]
		});
	}
}