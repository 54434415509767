import '../helpers/helpers';

import * as slickSliders from '../functions/slickSliders';
import * as scrollEvent from '../eventHandlers/scrollEvent';

// import * as Lazy from 'jquery-lazy';

document.addEventListener('DOMContentLoaded', function(){

	function toggleOverlay() {
		var overlay = $('.overlay');

		if(overlay.hasClass('open')) {
			overlay.removeClass('open');
			overlay.addClass('close');

			setTimeout(function(){
				overlay.removeClass('close');
			}, 250);
		} else if(!overlay.hasClass('close')) {
			overlay.addClass('open');
		}
	}

	var $lightgallery = $('.lightgallery'),
		$map = document.getElementById('map'),
		$mainSlider = document.querySelectorAll('.main-slider'),
		$sliderHolder = document.querySelectorAll('.slider-holder');

	if($mainSlider || $sliderHolder){
		slickSliders.init();
	}

	scrollEvent.init();

	$('.trigger-btn, .overlay-close').on('click', toggleOverlay);

	if($lightgallery.length) {
		// $(".lightgallery").lightGallery({
		// 	selector: 'this' 
		// });

		$(".gallery").lightGallery({
			selector: '.lightgallery',
			thumbnail: true
		});

		// Thumbnails
		// $(".small-gallery").lightGallery({
		// 	thumbnail:true
		// });
	}

	$('.btn--contact').on('click', function(){
		$('.popup-hld--contact').addClass('js-show');
	});

	$('.close-btn, .popup-blank').on('click', function(){
		$('.popup-hld').removeClass('js-show');
	});

	$('#overlay-menu li').on('mouseover', function(){
		var $this = $(this),
			myIndex = $this.index();

		$('.menu-photo-wrapp').eq(myIndex).addClass('active');
	});

	$('#overlay-menu li').on('mouseleave', function(){
		$('.menu-photo-wrapp').removeClass('active');
	});

	// $('#overlay-menu li').on('mouseover', function(){
	// 	var $this = $(this),
	// 		myIndex = $this.index();

	// 	$('.menu-photo-wrapp').eq(myIndex).addClass('active').animate({
	// 		z-index: "2",
	// 		opacity: "1",
	// 		transform: "translate(0px, 0%)"
	// 	});
	// });

	// $('#overlay-menu li').on('mouseleave', function(){
	//	var $this = $(this),
	// 	myIndex = $this.index();
	// 	
	//	$('.menu-photo-wrapp').removeClass('active').animate({
	// 		z-index: "0",
	// 		opacity: "0",
	// 		transform: "translate(0px, -50%)"
	// 	});
	// });

});

// $(window).on('load', function() {
	
// 	if(!Modernizr.csspointerevents && !Modernizr.touchevents) {
// 		var mySkrollr = skrollr.init({
// 			edgeStrategy: 'set',
// 			smoothScrolling: false,
// 			forceHeight: false
// 		});
// 	}

// });